<template>
    <b-col 
        xxs="12" 
        md="10"
    >

        <div class="text-center text-primary my-2"  v-if="loader">
            <b-spinner class="align-middle mb-1"></b-spinner>
            <div><b>CARGANDO</b></div>
        </div>

        <b-row v-if="questions.length">
            <b-col sm="12">
                <b-card-group deck>
                    <ExamCardQuestion
                        v-for="(question, index) in questions"
                        :key="question.id"
                        :question="question"
                        :index="index"
                    />
                </b-card-group>
            </b-col>
        </b-row>

    </b-col>
</template>

<script>

    import ExamCardQuestion from '@core/components/app-exam/ExamCardQuestion.vue';

    export default {
        props: {
            loader: {
                type: Boolean,
                required: true
            },
            questions: {
                type: Array,
                required: true
            }
        },
        components: {
            ExamCardQuestion
        }
    }

</script>

<style lang="scss" scoped>

    .card-deck {
        flex-flow: column;
    }

</style>