<template>
    <b-card
        class="card-question"
        border-variant="secondary"
        header-tag="header"
        header-bg-variant="secondary"
        header-text-variant="white"
    >

        <template #header>

            <b-col 
                class="card-question-question"
                xxs="12"
                md="10"
            >
                <strong>{{ index + 1 }}.</strong>
                <span>{{ question.question.question }}</span>
            </b-col>

            <b-col
                class="card-question-code"
                sm="2"
            >
                <strong>(CÓDIGO: {{ question.question.code }})</strong>
            </b-col>

        </template>
        <b-card-text>

            <b-row
                class="mt-1 mb-3"
                align-v="center" 
                align-h="between"
            >
                <ExamQuestionType :question_type_name="question.question.question_type.name" />
                <ExamQuestionTotalScore :score="question.total_score" />
            </b-row>

            <template v-if="question.question.question_type.id == '1'">

                <ExamQuestionRadio
                    :question="question.question_id" 
                    :options="question.question.answers" 
                    :marked="question.answers"
                />
                
            </template>

            <template v-else>

                <ExamQuestionCheckbox 
                    :question="question.question_id" 
                    :options="question.question.answers"
                    :marked="question.answers"
                />

            </template>

        </b-card-text>

    </b-card>
</template>

<script>

    import ExamQuestionTotalScore from '@core/components/app-exam/ExamQuestionTotalScore.vue';
    import ExamQuestionCheckbox from '@core/components/app-exam/ExamQuestionCheckbox.vue';
    import ExamQuestionRadio from '@core/components/app-exam/ExamQuestionRadio.vue';
    import ExamQuestionType from '@core/components/app-exam/ExamQuestionType.vue';

    export default {
        props: {
            index: {
                type: Number,
                required: true
            },
            question: {
                type: Object,
                required: true
            }
        },
        components: {
            ExamQuestionTotalScore,
            ExamQuestionCheckbox,
            ExamQuestionRadio,
            ExamQuestionType
        }
    }

</script>

<style lang="scss" scoped>

    .card-question {
        margin-bottom: 1.5rem;
        border: 1px solid var(--primary);
        border-radius: calc(.25rem - 1px) calc(.25rem - 1px) 6px 6px;
        box-shadow: 0 .125rem .25rem rgba(0,0,0,.5) !important;

        .card-header {
            display: flex;
            align-items: start;
            justify-content: center;
            padding-top: .75rem;
            text-align: left;
            margin: 0;
            border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
            background-color: rgba(221, 36, 45, 0.85) !important;
            line-height: 24px;

            .card-question-question {
                > strong {
                    margin-right: 1rem;
                }
            }

            .card-question-code {
                text-align: right;
            }
        }

        &:nth-child(1) {
            margin-top: 2rem;
        }

        &:nth-last-child(1) {
            margin-bottom: 4rem;
        }

        .card-body {
            text-align: left;
            line-height: 24px;
        }
    }

</style>