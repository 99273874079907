<template>
    <b-col sm="auto">
        <strong>PUNTAJE</strong>
        <b-badge variant="success">{{ score }}</b-badge>
    </b-col>
</template>

<script>

    export default {
        props: {
            score: {
                type: String,
                required: true
            }
        }
    }

</script>

<style lang="scss" scoped>

    .badge {
        text-transform: uppercase;
        font-weight: bold;
        margin-left: .5rem;
    }

</style>