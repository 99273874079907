<template>
    <b-col sm="auto">
        <strong>PREGUNTA</strong>
        <b-badge variant="success">{{ question_type_name }}</b-badge>
    </b-col>
</template>

<script>

    export default {
        props: {
            question_type_name: {
                type: String,
                required: true
            }
        }
    }

</script>

<style lang="scss" scoped>

    .badge {
        text-transform: uppercase;
        font-weight: bold;
        margin-left: .5rem;
    }

</style>