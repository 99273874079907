<template>
    <b-form-group class="mb-0">
        <b-form-checkbox
            v-for="option in options"
            v-model="selected"
            :key="option.id"
            :value="option.id"
            :name="`question-${option.question_id}`">
            {{ option.answer }}
        </b-form-checkbox>
    </b-form-group>
</template>

<script>

    import axios from '@axios';

    export default {
        props: {
            question: {
                type: Number,
                required: true
            },
            options: {
                type: Array,
                required: true
            },
            marked: {
                type: [String, Object],
                required: false,
                default: null
            }
        },
        data (){
            return {
                start: false,
                selected: []
            }
        },
        watch: {
            async selected (newValue) {
                if (this.start) 
                {
                    const { slug, examslug, attempt } = this.$router.currentRoute.params;
                    const payload = {
                        question_id: this.question,
                        answer: newValue.join(',')
                    }
                    await axios.post(`/students/courses/${slug}/exams/${examslug}/attemp/${attempt}/question/answers`, payload);
                }
            }
        },
        mounted (){
            this.selected = this.marked ? this.marked.split(',') : [];

            setTimeout(() => {
                this.start = true;
            }, 1000);
        }
    }

</script>