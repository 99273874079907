<template>
    <b-form-group class="mb-0">
        <b-form-radio-group
            v-model="selected"
            :options="options"
            value-field="id"
            text-field="answer"
            stacked
        ></b-form-radio-group>
    </b-form-group>
</template>

<script>

    import axios from '@axios';

    export default {
        props: {
            question: {
                type: Number,
                required: true
            },
            options: {
                type: Array,
                required: true
            },
            marked: {
                type: [String, Object],
                required: false,
                default: null
            }
        },
        data (){
            return {
                start: false,
                selected: null
            }
        },
        watch: {
            async selected (newValue) {
                if (this.start) 
                {
                    const { slug, examslug, attempt } = this.$router.currentRoute.params;
                    const payload = {
                        question_id: this.question,
                        answer: newValue
                    }
                    await axios.post(`/students/courses/${slug}/exams/${examslug}/attemp/${attempt}/question/answers`, payload);
                }
            }
        },
        mounted (){
            this.selected = this.marked ? parseInt(this.marked) : null;

            setTimeout(() => {
                this.start = true;
            }, 1000);
        }
    }

</script>