<template>
    <b-row 
        class="card-evaluation"
        align-v="center" 
        align-h="center"
    >

        <b-col
            class="card-evaluation-title"
            xxs="12" 
            md="10"
        >

            <b-card no-body>
                <b-card-header>
                    <feather-icon size="20" icon="PaperclipIcon" />
                    {{ examTitle }}
                </b-card-header>
            </b-card>

        </b-col>

        <b-col sm="auto" class="score-fixed">
            <span>Puntaje total: {{ totalScore }}</span>
        </b-col>

        <b-col sm="auto" class="time-fixed">
            <span>Tiempo: {{ displayHours }}:{{ displayMinutes }}:{{ displaySeconds }}</span>
        </b-col>

        <ExamQuestions :loader="loader" :questions="questions" />
        
        <b-button
            variant="success"
            class="bbutton-finish-exam"
            title="FINALIZAR EXAMEN"
            :disabled="disabled"
            @click="confirmToFinishExam"
        >
            <feather-icon size="50" icon="SendIcon" />
        </b-button>

    </b-row>
</template>

<script>

    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
    import { ref, onMounted, computed, onBeforeMount, onUnmounted } from '@vue/composition-api';
    import examStoreModule from '@views/modules/students/exams/examStoreModule';
    import ExamQuestions from '@core/components/app-exam/ExamQuestions.vue';
    import { useToast } from 'vue-toastification/composition';
    import VueSweetalert2 from 'vue-sweetalert2';
    import router from '@/router';
	import store from '@/store';
    import Vue from 'vue';    
	import 'animate.css';
	
	Vue.use(VueSweetalert2);

    export default {
        components: {
            ExamQuestions
        },
        setup() {

            // USE TOAST
			const toast = useToast();

            // REFS
            const examTitle = ref(null);
            const totalScore = ref(0);
            const endDate = ref(null);

            const disabled = ref(true);

            const questions = ref([]);
            const loader = ref(true);

            const displayHours = ref(0);
            const displayMinutes = ref(0);
            const displaySeconds = ref(0);

            const EXAM_APP_STORE_MODULE_NAME = 'app-exam'

			// REGISTER MODULE
			if (!store.hasModule(EXAM_APP_STORE_MODULE_NAME)) store.registerModule(EXAM_APP_STORE_MODULE_NAME, examStoreModule)

			// UNREGISTER ON LEAVE
			onUnmounted(() => {
				if (store.hasModule(EXAM_APP_STORE_MODULE_NAME)) store.unregisterModule(EXAM_APP_STORE_MODULE_NAME)
			});

            // CALL FUNCTION TO POPULATE OPTIONS FOR THE FILTERS
            onBeforeMount( async () => {
                await fetchExamContent();
            });

            onMounted(() => {
                showRemaining();
            });

            // COMPUTED
            const _seconds = computed(() => 1000);
            const _minutes = computed(() => _seconds.value * 60);
            const _hours = computed(() => _minutes.value * 60);

            // METHODS
            const fetchExamContent = async () => {

                const { slug, examslug, attempt } = router.currentRoute.params;

                store.dispatch('app-exam/fetchExamContent', { course: slug, exam: examslug, attempt })
                    .then( response => {

                        totalScore.value = response.data.totalScore;
                        examTitle.value = response.data.exam;

                        endDate.value = response.data.endDate;

                        questions.value = response.data.questions;

                        loader.value = false;
                        disabled.value = false;

                    })
                    .catch( (err) => {
						const message = err.response.data.message;						

                        toast({
                            component: ToastificationContent,
                            props: {
                                title: message,
                                icon: 'AlertTriangleIcon',
                                variant: 'danger'
                            }
                        });
					});

            };

            const formatNumber = (number) => (number < 10 ? '0' + number : number);

            const showRemaining = () => {

                const timer = setInterval(() => {

                    const now = new Date();
                    const end = new Date(endDate.value);

                    const distance = end.getTime() - now.getTime();

                    const secondsLeft = Math.round(distance / 1000);

                    if ((secondsLeft <= 10 && secondsLeft >= 1) || secondsLeft === 20 || secondsLeft === 30) 
                    {
                        toast({
                            component: ToastificationContent,
                            props: {
                                title: `Te quedan ${secondsLeft} segundos para terminar la evaluación.`,
                                icon: 'AlertTriangleIcon',
                                variant: 'warning'
                            }
                        });
                    }

                    if (distance < 0) 
                    {
                        clearInterval(timer);
                        finishExam();
                        return;
                    }

                    const hours = Math.floor(distance / _hours.value);
                    const minutes = Math.floor((distance % _hours.value) / _minutes.value);
                    const seconds = Math.floor((distance % _minutes.value) / _seconds.value);

                    displayMinutes.value = formatNumber(minutes);
                    displaySeconds.value = formatNumber(seconds);
                    displayHours.value = formatNumber(hours);

                }, 1000);

            };

            const confirmToFinishExam = () => {

                Vue.swal({
					title: '¿Está seguro(a) de finalizar la evaluación?',
					text: '¡Una vez se haya enviado sus respuestas, se emitirá su puntaje de forma automática!',
					icon: 'warning',
					showCancelButton: true,
					confirmButtonText: 'Sí, finalizar!',
					cancelButtonText: 'Cancelar',
					customClass: {
						confirmButton: 'btn btn-primary',
						cancelButton: 'btn btn-outline-danger ml-1'
					},
					showClass: {
						popup: 'animate__animated animate__tada'
					},
					buttonsStyling: false
				}).then(result => {

					if (result.value) finishExam();

				});

            };

            const finishExam = () => {

                disabled.value = true;

                const { slug, examslug, attempt } = router.currentRoute.params;

                store.dispatch('app-exam/fetchExamFinish', { course: slug, exam: examslug, attempt })
                    .then( response => {

                        router.push({ name: 'students-courses-content', params: { slug }, query: { tab: 'exam' } })

                        toast({
                            component: ToastificationContent,
                            props: {
                                title: response.data.message,
                                icon: 'CheckIcon',
                                variant: 'success'
                            }
                        });

                    })
                    .catch( (err) => {
                        const message = err.response.data.message;						

                        toast({
                            component: ToastificationContent,
                            props: {
                                title: message,
                                icon: 'AlertTriangleIcon',
                                variant: 'danger'
                            }
                        });
                    })
                    .finally( () =>  disabled.value = false);

            };

            return {
                // REFS
                totalScore,
                examTitle,

                questions,
                disabled,
                loader,

                displayMinutes,
                displaySeconds,
                displayHours,

                // METHODS
                confirmToFinishExam
            }
        }
    }

</script>

<style lang="scss" scoped>

    .card-evaluation {
        .card-evaluation-title .card {
            box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .5);
            border-radius: 10px;

            .card-header {
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 1rem 1.5rem;
                text-align: center;
                font-weight: bold;
                text-transform: uppercase;
                font-size: 1.45rem;
                border-radius: 8px;
                background-color: var(--primary);
                color: white;

                svg {
                    margin-right: 1rem;
                }
            }
        }

        .score-fixed {
            position: fixed;
            font-weight: bold;
            color: whitesmoke;
            left: 0;
            top: 40%;
            padding: 10px 15px !important;
            background-color: var(--primary);
            border-radius: 0 10px 10px 0;
            z-index: 100;
            text-transform: uppercase;
        }

        .time-fixed {
            position: fixed;
            font-weight: bold;
            color: whitesmoke !important;
            left: 0;
            top: 50%;
            padding: 10px 15px !important;
            background-color: #2DA397;
            border-radius: 0 10px 10px 0;
            z-index: 100;
            text-transform: uppercase;
        }

        .bbutton-finish-exam {
            position: fixed;
            font-weight: bold;
            color: #FFFFFF !important;
            bottom: 10px;
            right: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            height: 65px;
            width: 65px;
            z-index: 100;
            transition: all .5s;
            box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .5) !important;
            border: 3px solid transparent;

            > svg {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 50%;
            }

            &:hover {
                color: var(--success) !important;
                background: #FFFFFF !important;
                border: 3px solid var(--success);
            }
        }
    }

</style>

<style lang="scss">
	@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>