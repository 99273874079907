import axios from '@axios';

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        fetchExamContent(ctx, { course, exam, attempt }) {
            return new Promise((resolve, reject) => {
                axios
                    .post(`/students/courses/${course}/exams/${exam}/attemp/${attempt}/content`)
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            });
        },
        fetchExamFinish(ctx, { course, exam, attempt }) {
            return new Promise((resolve, reject) => {
                axios
                    .post(`/students/courses/${course}/exams/${exam}/attemp/${attempt}/finish`)
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            });
        }
    }
}